import * as React from 'react';
import Link from '../../global/link/link';
import Styles from './footer.module.scss';

export default props => {
    return (
        <footer className={`${Styles.footer} py-5`}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
                        <address className="m-0">
                            <h1 className="color-gold font-playfair">Schloss Westerburg</h1>
                            56457 Westerburg<br></br>
                            Deutschland
                        </address>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
                        <h1 className="color-gold font-playfair">Kontakt</h1>
                        <ul className="list-unstyled m-0">
                            <li>
                                <a className="unstyled" href="tel:+492663911401">Tel: +49 2663 911 401</a>
                            </li>
                            <li>
                                <a className="unstyled" href="fax:+492663911402">Fax: +49 2663 - 911 402</a>
                            </li>
                            <li>
                                <a className="unstyled" href="mailto:info@schlosswesterburg.de">Mail: info@schlosswesterburg.de</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
                        <h1 className="color-gold font-playfair">Bereiche</h1>
                        <ul className="list-unstyled m-0">
                            <li>
                                <Link to="/restaurant" className="unstyled">Restaurant</Link>
                            </li>
                            <li>
                                <Link to="/hochzeiten" className="unstyled">Hochzeiten</Link>
                            </li>
                            <li>
                                <Link to="/raeumlichkeiten" className="unstyled">Räumlichkeiten</Link>
                            </li>
                            <li>
                                <Link to="/kontakt" className="unstyled">Kontakt</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
                        <h1 className="color-gold font-playfair">Räumlichkeiten</h1>
                        <ul className="list-unstyled m-0">
                            <li>
                                <Link to="/raeumlichkeiten/ahnensaal" className="unstyled">Ahnensaal</Link>
                            </li>
                            <li>
                                <Link to="/raeumlichkeiten/rittersaal" className="unstyled">Rittersaal</Link>
                            </li>
                            <li>
                                <Link to="/raeumlichkeiten/kleiner-saal" className="unstyled">Kleiner Saal</Link>
                            </li>
                            <li>
                                <Link to="/raeumlichkeiten/schlosskueche" className="unstyled">Schlossküche</Link>
                            </li>
                            <li>
                                <Link to="/raeumlichkeiten/minnesaal" className="unstyled">Minnesaal</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr className="my-md-4"></hr>
                        <div className="d-flex flex-column flex-sm-row">
                            <a className={`unstyled ${Styles.expoLink}`} href="https://www.exportarts.io" target="_blank" rel="noopener noreferrer">
                                Made with
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                                </svg>
                                by exportarts.io
                            </a>
                            <div className="mt-3 mt-sm-0 ml-sm-auto">
                                <Link to="/impressum" className="unstyled d-block d-sm-inline">Impressum</Link>
                                <Link to="/datenschutz" className="unstyled ml-sm-3">Datenschutz</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
