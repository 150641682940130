import React, { useEffect, useState } from 'react';
import Link from '../link/link';
import Styles from './navbar.module.scss';

const LINKS = [
    {
        link: '/restaurant',
        text: 'Restaurant',
        fragment: ''
    },
    {
        link: '/hochzeit',
        text: 'Hochzeit',
        fragment: ''
    },
    {
        link: '/tagungen',
        text: 'Tagungen',
        fragment: ''
    },
    {
        link: '/kontakt',
        text: 'Kontakt',
        fragment: ''
    }
];

export default props => {
    const [isNavbarOpen, setNavbarOpen] = useState(false);
    const closeNavbar = () => {
        document.body.classList.remove('no-scroll');
        setNavbarOpen(false);
    }
    const openNavbar = () => {
        document.body.classList.add('no-scroll');
        setNavbarOpen(true);
    }
    const toggleNavbar = () => {
        if (isNavbarOpen) {
            closeNavbar();
        } else {
            openNavbar();
        }
    };
    const closeNavbarOnResize = () => {
        if (isNavbarOpen && window.innerWidth >= 768) {
            closeNavbar();
        }
    }

    useEffect(() => {
        window.addEventListener('resize', closeNavbarOnResize);
    }, []);

    return (
        <nav className={`navbar navbar-expand-md navbar-light bg-white d-flex flex-column ${Styles.nav} ${isNavbarOpen ? Styles.fullscreen : ''}`}>
            <div className="container">
                <Link className="navbar-brand pl-3 d-flex align-items-center" to="/">
                    <img height="52" src="/images/logo-schloss-westerburg.svg" className="brand-image" alt="Schloss Westerburg"></img>
                </Link>
                <button className="navbar-toggler border-0 outline-none" onClick={() => toggleNavbar()} aria-label={'Navigation ' + isNavbarOpen ? 'schließen' : 'öffnen'}>
                    {
                        isNavbarOpen ?
                        (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" className="fill-gold"/></svg>
                        ) :
                        (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" className="fill-gold"/></svg>
                        )
                    }
                </button>
                <div className={`collapse navbar-collapse text-center text-md-left ${isNavbarOpen ? 'show' : ''}`}>
                    <ul className="navbar-nav ml-md-auto">
                        {
                            LINKS.map((link, index) => (
                                <li key={index} className={`nav-item ${index !== 0 ? 'ml-md-3' : ''}`}>
                                    <Link className="unstyled p-0 color-gold text-uppercase"
                                        to={link.link + (link.fragment ? '#' + link.fragment : '')}
                                        callback={() => closeNavbar()}>
                                        {link.text}
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </nav>
    );
};
