import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Helmet from 'react-helmet';
import Footer from '../footer/footer';
import Link from '../link/link';
import Navbar from '../navbar/navbar';

// Helmet does not support React.Fragment, therefore the below syntax
// see: https://github.com/nfl/react-helmet/issues/342
const Meta = ({ title, description, image }) => (
    <Helmet>
        {title && <title>{title}</title>}
        {title && <meta name="og:title" content={title}></meta>}
        {description && <meta name="description" content={description}></meta>}
        {description && <meta name="og:description" content={description}></meta>}
        {image && <meta name="image" content={image}></meta>}
        {image && <meta name="og:image" content={image}></meta>}
    </Helmet>
);

export default props => {
    return (
        <div className={`${props.wrapperClassName || 'bg-1'}`}>
            <Meta
                title={`${((props.title !== null && props.title !== undefined) ? props.title : '') + ((props.titleDivider !== null && props.titleDivider !== undefined) ? props.titleDivider : '')}${props.titleSuffix ? props.titleSuffix : ''}`}
                description={props.description}
                image={props.image}
            />
            <Navbar/>
            <main>
                {props.children}
            </main>
            <Footer/>
            <CookieConsent
                location="bottom"
                buttonText="Okay"
                cookieName="west-cookie-consent"
                buttonClasses="my-2"
                style={{ background: '#fff', color: '#111' }}
                buttonStyle={{ background: '#967F53', color: '#fff', fontSize: '16px', padding: '4px 12px', alignSelf: 'center' }}
                expires={365}
            >
                <div className="container px-0 mx-0 md-md-auto">
                    <div className="row">
                        <div className="col">
                            Wir nutzen Cookies, um anonyme Nutzungsdaten zu erfassen und die Nutzererfahrung zu verbessern.{" "}
                            <Link className="unstyled" to="/datenschutz">Mehr erfahren.</Link>
                        </div>
                    </div>
                </div>
            </CookieConsent>
        </div>
    );
}
